import React from "react";
import { useSelector } from "react-redux";
import Sidenav from "../Partials/Sidenav";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Mainnav from "../Partials/Mainnav";
import SubSidenav from "../Partials/SubSidenav";
import { MainContent } from "../Partials/partials";
import ProviderList from "./ProviderList";
import NodeProviderList from "../NodeProvider/ProviderList";
import CreateNodeProvider from "../NodeProvider/CreateNodeProvider";
import TokenRemittance from "./TokenRemittance";
import CurrencyControl from "./CurrencyControl";
import OperationControl from "./OperationControl";
import RewardBooster from "./RewardBooster";

const EmailProvider = () => {
  const { toggleSubnav } = useSelector((state) => state.General);
  let items = [
    { id: 1, item: "Email Providers" },
    { id: 2, item: "Node Provider" },
    { id: 3, item: "Token Remittance" },
    { id: 4, item: "Supported Currencies" },
    { id: 5, item: "Operation Control" },
    //  { id: 6, item: "Reward Booster" },
  ];
  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav pageTitle="Settings" listItems={items} />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5">
          <SubSidenav />
          {toggleSubnav === 1 ? (
            <ProviderList />
          ) : toggleSubnav === 2 ? (
            <NodeProviderList />
          ) : toggleSubnav === 3 ? (
            <TokenRemittance />
          ) : toggleSubnav === 4 ? (
            <CurrencyControl />
          ) : toggleSubnav === 5 ? (
            <OperationControl />
          ) : (
            <RewardBooster />
          )}
          <CreateNodeProvider />
        </div>
      </MainContent>
    </div>
  );
};

export default EmailProvider;
