import React, { useEffect, useState } from "react";
import Mainnav from "../../Partials/Mainnav";
import Sidenav from "../../Partials/Sidenav";
import BreadCrumbs from "../../Partials/Breadcrumbs";
import { Link } from "react-router-dom";
import SubSidenav from "../../Partials/SubSidenav";
import { MainContent } from "../../Partials/partials";
import { StakeStatData, StakeStatValue } from "../../UserDetails/styles";
import { useDispatch, useSelector } from "react-redux";
import { getNewTransactionDetails } from "../../../Redux/actions";
import { numberWithCommas, shortenAddress } from "../../../Helper";
import { Table, TableHead, TableValues } from "../../Reusables/tables/tablestyles";
import CustomLoader from "../../Reusables/loaders/CustomLoader";
import { PersonalBox, PersonalInfo, UserDetailsAvatar } from "../../Devices/styles";
import { StakeStatValuePre } from "../style";
import { MdFileCopy } from "react-icons/md";
import EmptyCard from "../../Reusables/cards/EmptyCard";

const CryptoWithdrawaltDetailPage = ({ history }) => {

  const { newTransactionDetails } = useSelector((state) => state.Transactions);
  const { gettingNewTrancactionDetails } = useSelector((state) => state.Loading);
  const dispatch = useDispatch();
  const [withdrawalId, setWithdrawalId] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
  const cryptoType = "crypto-withdrawal";

  const goBack = () => {
    history.goBack();
  };

  // I added this in a useEffect, please check if it works effectively
  useEffect(() => {
    const newWithdrawalId = window.location.pathname.split("/").at(-1);
    setWithdrawalId(newWithdrawalId);
  }, []);

  useEffect(() => {
    if (withdrawalId) {
      dispatch(getNewTransactionDetails(cryptoType, withdrawalId));
    }
  }, [withdrawalId, dispatch]);

  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content detail-trans">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav
            pageTitle={
              <button
                className="pointer mb-0"
                onClick={goBack}
                style={{background: 'none', border: 'none'}}
              >
                {" "}
                <i className="material-icons">arrow_back</i> Crypto Withdrawal Report
              </button>
            }
          />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5 mb-5">
          <SubSidenav />
          { gettingNewTrancactionDetails ?
            <CustomLoader size="10" /> :
            <div className="card w3-animate-bottom">
            {Object.keys(newTransactionDetails).length !== 0 ?
             <div className="card-body p-6 ">
               <div className="row mb-2">
                 { newTransactionDetails.sender &&
                   <div className="col-lg-6 col-md-6">
                   <PersonalBox>
                     <UserDetailsAvatar
                       className="rounded-circle"
                       src="/Assets/images/avatars/user_icon.svg"
                       alt="User Avatar"
                     />
                      <PersonalInfo>
                        <span>{newTransactionDetails.sender && newTransactionDetails.sender.name ? newTransactionDetails.sender.name : "N/A"}</span>
                        <span>{newTransactionDetails.sender && newTransactionDetails.sender.email ? newTransactionDetails.sender.email : "N/A"}</span>
                      </PersonalInfo>
                     <Link to={`/user/${newTransactionDetails.sender.id}`}><div className="details min-width"> Details </div></Link>
                   </PersonalBox>
                   </div>
                 }

                 <div className="col-lg-3 col-md-6">
                   <StakeStatData>Reference Code</StakeStatData>
                   { newTransactionDetails.referenceCode ? (
                     <StakeStatValue>
                       {newTransactionDetails.referenceCode}
                     </StakeStatValue>
                   ) : "N/A"}
                 </div>

                 <div className="col-lg-3 col-md-6">
                   <StakeStatData>Transaction Hash</StakeStatData>
                   { newTransactionDetails.transactionHash ? (
                     <StakeStatValue>
                       {newTransactionDetails.transactionHash}
                     </StakeStatValue>
                   ) : "N/A"}
                 </div>
               </div>

               <div
                 className="row mt-2 pt-2"
                 style={{ borderTop: "1px solid #E8E8E8" }}
               >
                 <div className="col-lg-3 col-md-6">
                   <StakeStatData>Total Chargeable Amount</StakeStatData>
                   { newTransactionDetails.totalChargeableAmount ? (
                     <StakeStatValue>
                       {numberWithCommas(newTransactionDetails.totalChargeableAmount, 4)} {newTransactionDetails.currency}
                     </StakeStatValue>
                   ) : 0.00}
                 </div>

                 <div className="col-lg-3 col-md-6">
                   <StakeStatData>Amount</StakeStatData>
                   { newTransactionDetails.amount ? (
                     <StakeStatValue>
                       {numberWithCommas(newTransactionDetails.amount, 4)} {newTransactionDetails.currency}
                     </StakeStatValue>
                   ) : 0.00}
                 </div>
                 
                 <div className="col-lg-3 col-md-6">
                   <StakeStatData>Fee</StakeStatData>
                   {newTransactionDetails.fee ? <StakeStatValue>{numberWithCommas(newTransactionDetails.fee, 4)}</StakeStatValue> : 0.00}
                 </div>

                 <div className="col-lg-3 col-md-6">
                   <StakeStatData>Fee Remitted</StakeStatData>
                   {newTransactionDetails.isFeeRemitted !== undefined && (
                     <StakeStatValue>{newTransactionDetails.isFeeRemitted === true ? "True" : "False"}</StakeStatValue>
                     )}
                 </div>

               </div>

               <div
                 className="row mt-2 pt-2"
                 style={{ borderTop: "1px solid #E8E8E8" }}
               >
                   <div className="col-lg-12 col-md-12">
                     <StakeStatData>Description</StakeStatData>
                     { newTransactionDetails.description ? <StakeStatValue>{newTransactionDetails.description}</StakeStatValue> : "N/A"}
                   </div>
               </div>

               <div
                 className="row mt-2 pt-2"
                 style={{ borderTop: "1px solid #E8E8E8" }}
               >
                
                    <div className="col-md-6">
                     <StakeStatData>Receiver Wallet State Change</StakeStatData>
                     <div style={{ marginTop: "5px" }}>
                       <Table>
                         <TableHead>
                           <tr>
                             <th colSpan={2}>Available Balance</th>
                             <th colSpan={2}>Locked Balance</th>
                           </tr>
                         </TableHead>
                         <tbody>
                           <tr>
                           {/* Available Balance */}
                             <TableValues>Before</TableValues>
                             <TableValues>After</TableValues>
                           {/* Locked Balance */}
                             <TableValues>Before</TableValues>
                             <TableValues>After</TableValues>
                            </tr>
                            {newTransactionDetails.receiverWalletStateChange && newTransactionDetails.receiverWalletStateChange.length > 0 ? newTransactionDetails.receiverWalletStateChange.map((stateChange, index) => {
                              return (
                                <tr key={index}>
                                  <TableValues>
                                    {stateChange.availableBalanceBefore ? numberWithCommas(stateChange.availableBalanceBefore, 4) : 0}
                                  </TableValues>
                                  <TableValues>
                                    { stateChange.availableBalanceAfter ? numberWithCommas(stateChange.availableBalanceAfter, 4) : 0}
                                  </TableValues>
                                  <TableValues>
                                    { stateChange.lockedBalanceBefore ? numberWithCommas(stateChange.lockedBalanceBefore, 4) : 0}
                                  </TableValues>
                                  <TableValues>
                                    { stateChange.lockedBalanceAfter ? numberWithCommas(stateChange.lockedBalanceAfter, 4) : 0}
                                  </TableValues>
                                </tr>
                              )
                              }): ""}
                         </tbody>
                       </Table>
                     </div>
                    </div>
                  
                  <div className="col-lg-6 col-md-6">
                     <StakeStatData>Destination Wallet Address</StakeStatData>
                     { newTransactionDetails.destinationWalletAddress ? <StakeStatValue>{newTransactionDetails.destinationWalletAddress}</StakeStatValue> : "N/A"}
                   </div>
               </div>

               <div
                 className="row mt-2 pt-2"
                 style={{ borderTop: "1px solid #E8E8E8", marginBottom: "1rem"}}
                 >
                  <div className="col-md-12">
                     <StakeStatData>Wallet Transaction</StakeStatData>
                     <div style={{ marginTop: "5px" }} className="table-on-mobile">
                       <Table>
                         <TableHead>
                           <tr>
                             <th scope="col">Reference</th>
                             <th scope="col">Total Chargeable Amount</th>
                             <th scope="col">Amount</th>
                             <th scope="col">Fee</th>
                             <th scope="col">Fee Remitted</th>
                           </tr>
                         </TableHead>
                         <tbody style={{marginTop: "4px"}}>
                             <tr>
                             
                             {newTransactionDetails.referenceCode ? <TableValues data-label="Reference">
                                <div 
                                  onClick={() => {
                                    navigator.clipboard.writeText(newTransactionDetails.referenceCode);
                                    setIsCopied(true);
                                    setTimeout(() => {
                                      setIsCopied(false);
                                    }, 1500);
                                  }}
                                  >
                                  <span className="">{shortenAddress(newTransactionDetails.referenceCode)}</span>
                                  <span className="pl-2"><MdFileCopy /></span>
                                  <span className="pl-2">{isCopied ? "Copied" : ""}</span>
                                </div>
                              </TableValues> : "N/A"}

                              {newTransactionDetails.totalChargeableAmount ? 
                                <TableValues data-label="Total Deposit Amount">{numberWithCommas(newTransactionDetails.totalChargeableAmount, 4)} {newTransactionDetails.currency}</TableValues> : 0.00 }

                              {newTransactionDetails.amount ? 
                                <TableValues data-label="Amount">{numberWithCommas(newTransactionDetails.amount, 4)} {newTransactionDetails.currency}</TableValues> : 0.00 }

                               {newTransactionDetails.fee ? 
                                <TableValues data-label="Fee">{newTransactionDetails.fee}</TableValues> : 0.00 }
                               
                               {newTransactionDetails.isFeeRemitted !== undefined ? 
                                <TableValues data-label="Fee Remitted">{newTransactionDetails.isFeeRemitted === true ? "True" : "False"}</TableValues> : "N/A" }


                             </tr>
                         
                         </tbody>
                       </Table>
                     </div>
                   </div>
               </div>

               <div
                 className="row mt-2 pt-2"
                 style={{ borderTop: "1px solid #E8E8E8" }}
                 >
                     <div className="col-lg-12 col-md-12">
                       <StakeStatData>Crypto Withdrawal Webhook</StakeStatData>
                       {newTransactionDetails.cryptoWithdrawalWebhook ? (
                         <StakeStatValuePre>{JSON.stringify(newTransactionDetails.cryptoWithdrawalWebhook, null, 2)}</StakeStatValuePre>) : "N/A"}
                     </div>
                   
               </div>
               
             </div> : 
              <EmptyCard
              img="/Assets/emptytransactions.svg"
              title={"Sorry,  you have no transaction history"}
            /> }
           </div>
          }
        </div>
      </MainContent>
    </div>
  );
};

export default CryptoWithdrawaltDetailPage;
