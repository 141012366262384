import React from "react";
import BreadCrumbs from "../Partials/Breadcrumbs";
import Mainnav from "../Partials/Mainnav";
import { MainContent } from "../Partials/partials";
import Sidenav from "../Partials/Sidenav";
import SubSidenav from "../Partials/SubSidenav";
import { useSelector } from "react-redux";
import ActiveAdmins from "./ActiveAdmins";
import InactiveAdmins from "./InactiveAdmins";
import InviteAdmin from "./Modal/InviteAdmin";
// import { handleModal } from "../../Redux/actions";
import ChangeRole from "../AdminDetails/modals/ChangeRole";


function index() {
  //const dispatch = useDispatch();
  const { toggleSubnav } = useSelector((state) => state.General);
  return (
    <div className="row">
      <Sidenav />
      <MainContent className="main-content">
        <div className="main-navbar sticky-top bg-white mb-5">
          <Mainnav
            pageTitle="Admins"
            listItems={[
              { id: 1, item: "Active" },
              { id: 2, item: "Inactive" },
            ]}
          />
        </div>
        <BreadCrumbs />
        <div className="main-content-container container-fluid px-4 mt-5">
          <SubSidenav />
          {/* <div className="card card-small mb-4 w3-animate-bottomd">
            <div className="row">
              <div className="col-lg-12 p-3">
                <div className="fl-right pr-5">
                  <button onClick={() =>
                    dispatch(handleModal(16)
                    )} 
                    className="btn wicryptColor"> 
                      INVITE ADMIN
                  </button>
                </div>
              </div>
            </div>
          </div> */}
          {toggleSubnav === 1 ? <ActiveAdmins /> : <InactiveAdmins />}
        </div>
        <InviteAdmin />
        <ChangeRole />
      </MainContent>
    </div>
  );
}

export default index;
